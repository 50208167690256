import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {ROUTES} from './app.routes';
import {ViewModule} from './view/view.module';
import {StoreModule} from '@ngrx/store';
import {reducers, metaReducers} from './core/store/reducers';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment, ENV_PROVIDERS} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {UserEffects} from './core/store/effects/user.effects';
import {PharmacyUserEffects} from './core/store/effects/pharmacyUser.effects';
import {EsareEffects} from './core/store/effects/esare.effects';
import {PromotionsEffects} from './core/store/effects/promotions.effects';
import {VaccineEffects} from './core/store/effects/vaccine.effects';
import {AccountEffects} from './core/store/effects/account.effects';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {APP_RESOLVER_PROVIDERS} from './app.resolver';
import {AuthGuardService} from './core/services/auth-guard.service';
import {AuthService} from './core/services/api/auth.service';
import {UserRestService} from './core/services/api/user.rest-service';
import {AccountRestService} from './core/services/api/account.rest-service';
import {DatePipe, registerLocaleData} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select'; //RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS
import {MatTreeModule} from '@angular/material/tree';
import {CUSTOM_DATE_FORMATS} from './core/models/date-format.model';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {CalendarService} from './core/services/calendar.service';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {MatPaginatorIntlDe} from '../assets/i18n/mat-paginator-intl.de';
import {NotificationModule} from './modules/shared/notification/notification.module';
import {LogService} from './core/services/utility/log.service';
import {ConsoleLogService} from './core/services/utility/console-log.service';
import {ViewPublicModule} from './view-public/view-public.module';
import {MessageModule} from './modules/shared/message/message.module';
import {NotificationPublicModule} from './modules/shared/notification-public/notification-public.module';
import {RegistrationHeadingModule} from './modules/authentication/registration/registration-heading/registration-heading.module';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {MessageEffects} from './core/store/effects/message.effects';
import {FirstLoginDialogComponent} from './modules/authentication/first-login-dialog/first-login-dialog.component';
import { DataCyDirective } from './modules/shared/directives/data-cy/data-cy.directive';
import { EsareRestService } from './core/services/api/esare.rest-service';
import { PromotionsRestService } from './core/services/api/promotions.rest-service';
import {PromotionProductsTempListModule} from './modules/promotions/promotion-products/promotion-products-temp-list/promotion-products-temp-list.module';
import { VaccineRestService } from './core/services/api/vaccine.rest-service';
import { VendorsEffects } from './core/store/effects/vendors.effects';
import {MatomoReportsModule} from './modules/matomo-reports/matomo-reports.module';
import {MatomoRestService} from './core/services/api/matomo.rest-service';
import {DelayInfoEffects} from './core/store/effects/delay-info.effects';
import {DistributionCenterRestService} from './core/services/api/distribution-center.rest.service';
import {DelayInfoService} from './core/services/delay-info.service';
import {DelayInfoRestService} from './core/services/api/delay-info.rest-service';
import {MatomoEffects} from './core/store/effects/matomo.effects';
import {MatomoService} from './core/services/matomo.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {BeraterUserEffects} from './core/store/effects/beraterUser.effects';


registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AuthGuardService,
    AuthService,
    UserRestService,
    AccountRestService,
    CalendarService,
    EsareRestService,
    PromotionsRestService,
    VaccineRestService,
    MatomoRestService,
    DelayInfoService,
    DelayInfoRestService,
    DistributionCenterRestService,
    MatomoService
];

/* Disables Ripple globally if provided
const globalRippleConfig: RippleGlobalOptions = {disabled: true};*/

@NgModule({
    declarations: [
        AppComponent,
        FirstLoginDialogComponent,
        DataCyDirective
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatRippleModule,
        MatTreeModule,
        BrowserAnimationsModule,
        MatDialogModule,
        ViewModule,
        ViewPublicModule,
        MessageModule,
        NotificationModule,
        NotificationPublicModule,
        PromotionProductsTempListModule,
        RouterModule.forRoot(ROUTES, {
            useHash: true,
            preloadingStrategy: PreloadAllModules,
            //enableTracing: true,
            anchorScrolling: 'enabled',
            relativeLinkResolution: 'legacy'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        StoreModule.forRoot(reducers, {metaReducers}),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot(
            [
                UserEffects,
                PharmacyUserEffects,
                EsareEffects,
                PromotionsEffects,
                VaccineEffects,
                AccountEffects,
                VendorsEffects,
                MessageEffects,
                DelayInfoEffects,
                BeraterUserEffects,
                MatomoEffects
            ]
        ),
        RegistrationHeadingModule,
        MatomoReportsModule
    ],
    providers: [
        ENV_PROVIDERS,
        APP_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        },
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlDe
        },
        /*        {
                    provide: MAT_RIPPLE_GLOBAL_OPTIONS,
                    useValue: globalRippleConfig
                },*/
        DatePipe,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS},
        {
            provide: LogService,
            useClass: ConsoleLogService
        },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})

export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
