import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ROUTES} from './view.routes';
import {ViewComponent} from './view.component';
import {NavComponent} from './nav/nav.component';
import {HeaderComponent} from './header/header.component';
import {ErrorComponent} from './error/error.component';
import { MatBadgeModule } from '@angular/material/badge';
import {NotificationModule} from '../modules/shared/notification/notification.module';
import {QuickNavComponent} from './quick-nav/quick-nav.component';
import {DelayInfoModule} from '../modules/delay-info/delay-info.module';
// import {AgmCoreModule} from '@agm/core';

@NgModule({
    imports: [
        CommonModule,
        ROUTES,
        MatBadgeModule,
        NotificationModule
        /*AgmCoreModule.forRoot({
             apiKey: 'AIzaSyDsqZJGhUk4dUqSYmqLp805yZGsxdISRJg'
             apiKey is required, unless you are a
            premium customer, in which case you can
            use clientId

        })*/
    ],
    declarations: [
        ViewComponent,
        NavComponent,
        HeaderComponent,
        ErrorComponent,
        QuickNavComponent
    ],
    exports: [
        ViewComponent
    ]
})
export class ViewModule {
}
