import {Injectable} from '@angular/core';
import {SliderManagementModel} from '../../modules/marketing-management/slider-management/slider-management.model';
import {BlobService} from './blob.service';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})

export class MarketingService {
    slides = [];

    constructor(private blobService: BlobService,
                private http: HttpClient) {
    }

    addSlide(slide: SliderManagementModel, type: string) {
        this.slides.push(slide);
        return this.uploadSlides(type);
    }

    updateSlide(slide: SliderManagementModel, index, type: string) {
        this.slides[index] = slide;
        return this.uploadSlides(type);
    }

    deleteSlide(index, type: string) {
        const deleted = this.slides.splice(index, 1);
        if (deleted[0].image) {
            this.blobService.deleteImage(deleted[0].image);
        }
        this.uploadSlides(type);
    }

    async downloadList(type: string) {
        this.slides = JSON.parse(<string>await this.blobService.getData(type + '.json'));
        return this.slides;
    }

    uploadSlides(type: string) {
        return this.blobService.setData(type + '.json', JSON.stringify(this.slides));
    }

    uploadImage(file, name) {
        return this.blobService.uploadImage(file, name);
    }

    async duplicate() {
        return {requestId: null, errorCode: 409};
    }

    checkDates(slide, index?) {
        const start = moment(slide.start);
        const end = moment(slide.end);
        if (slide.location.length > 0) {
            const pos = slide.location;
            for (const xslide of this.slides) {
                if (pos !== xslide.location) {
                    continue;
                }

                if (index !== null && index === this.slides.indexOf(xslide)) {
                    continue;
                }

                if (start.isBetween(moment(xslide.start), moment(xslide.end), undefined, '[]')) {
                    return true;
                }

                if (end.isBetween(moment(xslide.start), moment(xslide.end), undefined, '[]')) {
                    return true;
                }

                if (moment(xslide.start).isBetween(start, end, undefined, '[]')) {
                    return true;
                }

                if (moment(xslide.end).isBetween(start, end, undefined, '[]')) {
                    return true;
                }
            }
        }
        return false;
    }
}
