import {Component, ElementRef, Inject, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {Nav} from "../../core/models/nav.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {UserService} from "../../core/services/user.service";
import {User} from "../../core/models/user.model";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnDestroy {

    @ViewChildren("toggleArrow", {read: ElementRef}) nodes: QueryList<ElementRef>;
    public nav: Nav;
    public user: User;
    public active: string | boolean;
    public menuActive: boolean = false;
    private ngUnsubscribe$ = new Subject<any>();

    /**
     * @param router
     * @param route
     * @param userService
     */
    constructor(private router: Router, private route: ActivatedRoute,
                @Inject(UserService) private userService: UserService) {
        this.nav = this.userService.getNav();
        this.user = this.userService.getUser();

        router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((val) => {
                if (val instanceof NavigationEnd) {
                    if (this.route.snapshot.children.length > 0) {
                        this.active = this.nav.getProperty(this.route.snapshot.children[0].routeConfig.path, 'path');
                    }
                }
            }
        );

    }

    toggleMenuActive() {
        this.menuActive = !this.menuActive;
    }

    navigateToFragment() {
        this.router.navigate(['/dashboard'], {fragment: 'exklusivangebote'});
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }
}
